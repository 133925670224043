<script lang="ts" setup>
const props = withDefaults(
	defineProps<{
		variant?: number;
	}>(),
	{
		variant: 1
	}
);

const prepareImgUrl = useImage();
const background = `url(${prepareImgUrl(`/nuxt-img/cosmic-boxes/bg-chest-${props.variant}.png`, {
	format: "avif",
	width: 352,
	height: 352
})}) no-repeat center/cover`;
</script>

<template>
	<div :class="['chest', `chest-${variant}`]">
		<div class="wrapp-chest">
			<NuxtImg
				class="box-icon"
				:src="`/nuxt-img/cosmic-boxes/chest-${variant}.png`"
				width="220"
				height="190"
				format="avif"
				loading="lazy"
				:alt="`chest-${variant}`"
			/>
		</div>
	</div>
</template>

<style scoped lang="scss">
.chest {
	display: inline-flex;
	flex-shrink: 0;
	position: relative;
	width: 352px;
	background: v-bind(background);

	&::before {
		content: "";
		display: flex;
		width: 100%;
		padding-top: 100%;
	}

	.box-icon {
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		z-index: 1;
	}

	&.chest-1 .box-icon {
		animation: cosmic 8s linear -1s infinite alternate;
	}

	&.chest-2 .box-icon {
		animation: cosmic 10s linear -5s infinite alternate;
	}

	&.chest-3 .box-icon {
		animation: cosmic 12s linear 1s infinite alternate;
	}
}

.wrapp-chest {
	width: 62%;
	top: 22%;
	left: 20%;
	position: absolute;

	&::before {
		content: "";
		display: block;
		padding-top: calc(190 / 220 * 100%);
	}
}

@keyframes cosmic {
	0% {
		transform: translateX(0) translateY(0) rotate(0deg);
	}
	20% {
		transform: translateX(4px) translateY(4px) rotate(2deg);
	}
	40% {
		transform: translateX(6px) translateY(8px) rotate(4deg);
	}
	60% {
		transform: translateX(4px) translateY(10px) rotate(2deg);
	}
	80% {
		transform: translateX(0px) translateY(6px) rotate(-4deg);
	}
	100% {
		transform: translateX(-3px) translateY(2px) rotate(-2deg);
	}
}
</style>
